import { Col, Container, Row, Image } from "react-bootstrap";
// import { Clock } from "./Clock";

export const Header = () => {
  return (
    <>
      {/* <Container>
        <Row>
        <Col xs={8} lg={10}>
                
            <h4 className=" rectangle1 text-center">Расписание корпоративных автобусов</h4>
          </Col>
          <Col xs={2} lg={2}>
          <img src="emco-logo.png" className="emcologo"/>

          </Col>
        </Row>
      </Container> */}
      <Container>
        <Row className="highlight rasp">
          <Row className="align-items-center">
            <Col xs={8} lg={10}>
              <h6 className="rectangle1 ">
              Расписание корпоративных автобусов
              </h6>
            </Col>
            {/* </Row>
            <Row> */}
            <Col  xs={2} lg={2}>
                <img src="emco-logo.png" className="emcologo align-middle"/>
                {/* <Clock className="time" /> */}
            </Col>
            </Row>
        </Row>
        {/* <hr/> */}
      </Container>
    </>
  );
};
