import React from "react";
import {
  YMaps,
  Map,
  GeolocationControl,
  TypeSelector,
  ZoomControl,
  FullscreenControl,

} from "react-yandex-maps";
import { useRef } from "react";
import { MapsBusStopDots } from "./MapsBusStopDots";


export const MapsAll = (props) => {
  const map = useRef(null);
  
  const { isVisible } = props;

  const addRoute = (ymaps) => {
    const multiRoute = new ymaps.multiRouter.MultiRoute(
      {
        referencePoints: props.point,
        params: {
          routingMode: "auto",
          editorMidPointsType: "way",
          viaPointVisible:false,
          pinVisible:false,
        },
      },
      {
        boundsAutoApply: true,
        wayPointStartIconColor: "#FFFFFF",
        wayPointStartIconFillColor: "#B3B3B3",
        routeActiveStrokeWidth: 4,
        routeActiveStrokeStyle: "solid",
        routeActiveStrokeColor: "#002233",
      }
    );

    map.current.geoObjects.add(multiRoute);
  };

  return (
    <div className="">
      <YMaps query={{ apikey: "a8a96f1c-38a6-4841-b93e-59ad6f99c147" }}>
        <Map
          modules={["multiRouter.MultiRoute"]}
          state={props.state}
          instanceRef={map}
          onLoad={addRoute}
          width="100%"
          height="400px"
        >
          <GeolocationControl options={{ float: "left" }} />
          <TypeSelector options={{ float: "right" }} />
          <ZoomControl options={{ float: "right" }} />
          <FullscreenControl />
          {isVisible && <MapsBusStopDots />}

        </Map>
      </YMaps>
      {/* <hr /> */}
    </div>
  );
};
