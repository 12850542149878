// import { Main } from "./MainPage";
// import { Rasp } from "./RaspPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from "./components/Header";
import { RPPage } from "./RPPage";
// import { MapsBusStop } from './components/MapsBusStop';


function App() {
  return (
    <div className="App">
      <Header />
      <RPPage />
      {/* <Main /> */}
      {/* <Rasp /> */}

      {/* <MapsBusStop /> */}
    </div>
  );
}

export default App;
