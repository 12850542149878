import React, { useEffect, useState } from "react";
import busData from "../json/bus.json";
import {Badge, Col, Row} from "react-bootstrap";

export const BusStopDotsCircle = () => {
  const [busPoint, setJsonData] = useState(null);

  useEffect(() => {
    setJsonData(busData.bus);
  }, []);

  return (
      <Row className="justify-content-md-left">
        {busPoint && busPoint.map((i) => (
            <Col key={i.id} lg="auto">
              <Col lg={3}>
                <Badge style={{backgroundColor: i.pincolor}} bg={i.pincolor}>{i.id} - {i.name}
                </Badge>
               </Col>
            </Col>
        ))}
      </Row>
  );
};




