import React, { useState } from 'react';
import { Button, Col, Row, Form, Alert } from "react-bootstrap";
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug} from "@fortawesome/free-solid-svg-icons/faBug";

export const Repost = () => {
    const [showWidget, setShowWidget] = useState(false);
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);

    const sendMessage = async () => {
        try {
            const botToken = '6031980666:AAEm2wz6JtnD4yJ6nxNIBA5S9iU7BD-ScWQ'; // Замените на свой токен бота
            const chatId = '940362442'; // Замените на ID вашего чата

            const response = await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
                chat_id: chatId,
                text: message,
            });

            setResponse(`Сообщение успешно отправлено`);
            setIsAlertVisible(true);

            // Очищаем сообщение после отправки
            setMessage('');

            // Скрываем Alert через 3 секунды
            setTimeout(() => {
                setIsAlertVisible(false);
            }, 3000);
        } catch (error) {
            setResponse(`Ошибка при отправке сообщения`);
            setIsAlertVisible(true);
        }
    };

    return (
        <>
            <Row>
                <Col lg={2}>
                    <Button variant={"secondary"}
                        onClick={() => setShowWidget(!showWidget)}
                    >
                        <FontAwesomeIcon icon={faBug} /> Нашли ошибку?
                    </Button>
                </Col>
                <Col lg={10}>
                    {showWidget && (
                        <Row>
                            <Col lg={10}>
                                <Form.Control
                                    type="textarea"
                                    id="feedback"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                {isAlertVisible && <Alert variant="success">{response}</Alert>}
                            </Col>
                            <Col lg={2}>
                                <Button onClick={sendMessage}>Отправить</Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};
