import React from "react";
import {
  Placemark,
} from "react-yandex-maps";
import { useEffect, useState } from "react";

// import jsondata from "../json/BusStop.json";
import busstoppoint from "../json/bus.json";
// import { Container, Row, Col, Table } from "react-bootstrap";

export const MapsBusStopDots = () => {

  const [busStopData, setBusStopData] = useState(null);
  // const [jsonData, setJsonData] = useState(null);
  // const [jsonDataUgli, setJsonDataUgli] = useState(null);
  // const [jsonDataRazrez, setJsonDataRazrez] = useState(null);
  // const [jsonDataKrasnopole, setJsonDataKrasnopole] = useState(null);
  // const [jsonDataKonvejernaya, setJsonDataKonvejernaya] = useState(null);


  useEffect(() => {
    setBusStopData(busstoppoint)
    // setJsonData(jsondata.shaxtersk);
    // setJsonDataUgli(jsondata.ugligorsk);
    // setJsonDataRazrez(jsondata.razrez);
    // setJsonDataKrasnopole(jsondata.krasnopole);
    // setJsonDataKonvejernaya(jsondata.konvejernaya);

  }, []);

  return (
    <>
      {busStopData &&
          busStopData.bus.map((busStop) => (
          <Placemark
            key={busStop.id}
            modules={["geoObject.addon.balloon"]}
            geometry={JSON.parse(busStop.pin)} // Преобразуйте строку с координатами в объект
            properties={{
              balloonContentHeader: busStop.name,
              balloonContentBody: busStop.imgBaloon,
              iconContent: busStop.id,
              hintContent: "",
            }}
            options={{
              iconColor: busStop.pincolor,
            }}
          />
        ))}
      {/*{jsonData &&*/}
      {/*  jsonData.bus.map((busStop) => (*/}
      {/*    <Placemark*/}
      {/*      key={busStop.id}*/}
      {/*      modules={["geoObject.addon.balloon"]}*/}
      {/*      geometry={JSON.parse(busStop.pin)} // Преобразуйте строку с координатами в объект*/}
      {/*      properties={{*/}
      {/*        balloonContentHeader: busStop.name,*/}
      {/*        balloonContentBody: busStop.imgBaloon,*/}
      {/*        // iconContent: busStop.id,*/}
      {/*        hintContent: "",*/}
      {/*      }}*/}
      {/*      options={{*/}
      {/*        iconColor: busStop.pincolor,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*{jsonDataUgli &&*/}
      {/*  jsonDataUgli.bus.map((busStopUgli) => (*/}
      {/*    <Placemark*/}
      {/*      key={busStopUgli.id}*/}
      {/*      modules={["geoObject.addon.balloon"]}*/}
      {/*      geometry={JSON.parse(busStopUgli.pin)} // Преобразуйте строку с координатами в объект*/}
      {/*      properties={{*/}
      {/*        balloonContentHeader: busStopUgli.name,*/}
      {/*        balloonContentBody: busStopUgli.imgBaloon,*/}
      {/*        // iconContent: busStopUgli.id,*/}
      {/*        hintContent: "",*/}
      {/*      }}*/}
      {/*      options={{*/}
      {/*        iconColor: busStopUgli.pincolor,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ))}*/}


      {/*{jsonDataRazrez &&*/}
      {/*  jsonDataRazrez.bus.map((busStopUgli) => (*/}
      {/*    <Placemark*/}
      {/*      key={busStopUgli.id}*/}
      {/*      modules={["geoObject.addon.balloon"]}*/}
      {/*      geometry={JSON.parse(busStopUgli.pin)} // Преобразуйте строку с координатами в объект*/}
      {/*      properties={{*/}
      {/*        balloonContentHeader: busStopUgli.name,*/}
      {/*        balloonContentBody: busStopUgli.imgBaloon,*/}
      {/*        // iconContent: busStopUgli.id,*/}
      {/*        hintContent: "",*/}
      {/*      }}*/}
      {/*      options={{*/}
      {/*        iconColor: busStopUgli.pincolor,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ))}*/}



      {/*{jsonDataKrasnopole &&*/}
      {/*  jsonDataKrasnopole.bus.map((busStopUgli) => (*/}
      {/*    <Placemark*/}
      {/*      key={busStopUgli.id}*/}
      {/*      modules={["geoObject.addon.balloon"]}*/}
      {/*      geometry={JSON.parse(busStopUgli.pin)} // Преобразуйте строку с координатами в объект*/}
      {/*      properties={{*/}
      {/*        balloonContentHeader: busStopUgli.name,*/}
      {/*        balloonContentBody: busStopUgli.imgBaloon,*/}
      {/*        // iconContent: busStopUgli.id,*/}
      {/*        hintContent: "",*/}
      {/*      }}*/}
      {/*      options={{*/}
      {/*        iconColor: busStopUgli.pincolor,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ))}*/}




      {/*{jsonDataKonvejernaya &&*/}
      {/*  jsonDataKonvejernaya.bus.map((busStopUgli) => (*/}
      {/*    <Placemark*/}
      {/*      key={busStopUgli.id}*/}
      {/*      modules={["geoObject.addon.balloon"]}*/}
      {/*      geometry={JSON.parse(busStopUgli.pin)} // Преобразуйте строку с координатами в объект*/}
      {/*      properties={{*/}
      {/*        balloonContentHeader: busStopUgli.name,*/}
      {/*        balloonContentBody: busStopUgli.imgBaloon,*/}
      {/*        // iconContent: busStopUgli.id,*/}
      {/*        hintContent: "",*/}
      {/*      }}*/}
      {/*      options={{*/}
      {/*        iconColor: busStopUgli.pincolor,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ))}*/}
    </>
  );
};
