import { useState, useEffect } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";

import jsondata from "../json/UgliPortRazrez.json";

export const UgliPortRazrez = () => {
  const [data, setJsonData] = useState([]);
    const [dataTitle, setJsonDataTitle] = useState([]);

  useEffect(() => {
      // Импортируем расписание из JSON-файла
      setJsonData(jsondata.route);
      setJsonDataTitle(jsondata.title);

    }, []);

  return (
    <Container>
      <Row>
              <h4 className="text-center">{dataTitle}</h4>
        <Col>
        {data.map((i) => (
          <ul className="timeline" key={i.id}>
<Card>
  <Card.Body>
  <p className="text-center">{i.name}</p>

            {i.value.map((r) => (

            <li className="timeline-item">
              <img src={r.pin} alt="" />

            <div className="timeline-info">
            <span>{r.name}</span>
            </div>
            <div className="timeline-marker"></div>
            <div className="timeline-content">
            <h3 className="timeline-title"> {r.time2}</h3>
            {/* <h3 className="timeline-title">{r.time1} - {r.time2}</h3> */}

            {/* <p></p> */}
            </div>
            </li>

            ))}
            <li className="timeline-item period">
            <div className="timeline-info"></div>
            <div className="timeline-marker"></div>
        </li>
</Card.Body>
</Card>
<br/>
          </ul>
        ))
        }
        </Col>
      </Row>

    </Container>
  );
};