import { useState, useEffect } from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";

import { UgliUdar } from "./components/UgliUdar";
import { UdarUgli } from "./components/UdarUgli";
import { UgliPortRazrez } from "./components/UgliPortRazrez";
import { RazrezUgliPort } from "./components/RazrezUgliPort";

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faRightLeft, faMapLocation, faLocationDot} from '@fortawesome/free-solid-svg-icons'
// end fontawesome

import { MapsAll } from "./components/MapsAll";
import { Clock } from "./components/Clock";
import { ShaxRazrez } from "./components/ShaxRazrez";
import { RazrezShax } from "./components/RazrezShax";
// import { MapsAllShax } from "./components/MapsAllShax";

import { KrasnopoleRazrez } from "./components/KrasnopoleRazrez";
import { RazrezKrasnopole } from "./components/RazrezKrasnopole";
import { ShaxterskRMMRazrez } from "./components/ShaxterskRMMRazrez";
import { RazrezShaxterskRMM } from "./components/RazrezShaxterskRMM";
import { ShaxterskUdarnoe } from "./components/ShaxterskUdarnoe";
import { UdarnoeShaxtersk } from "./components/UdarnoeShaxtersk";
import { UglegorskRazrez } from "./components/UglegorskRazrez";
import { RazrezUglegorsk } from "./components/RazrezUglegorsk";
import { KonvejernayaUgli } from "./components/KonvejernayaUgli";
import { ShaxterskShaxterskPort } from "./components/ShaxterskShaxterskPort";
import { ShaxterskUgliPort } from "./components/ShaxterskUgliPort";
import { UgliKonvejernaya } from "./components/UgliKonvejernaya";
import { UgliPortShaxterskPort } from "./components/UgliPortShaxterskPort";
import { UgliPortShaxtersk } from "./components/UgliPortShaxtersk";
import { ShaxterskPortUgliPort } from "./components/ShaxterskPortUgliPort";
import { KonvejernayaShaxtersk } from "./components/KonvejernayaShaxtersk";
import { KrasnopoleUgli } from "./components/KrasnopoleUgli";
import { KrasnopoleShaxtersk } from "./components/KrasnopoleShaxtersk";
import { ShaxterskKrasnopole } from "./components/ShaxterskKrasnopole";
import { ShaxterskKonvejernaya } from "./components/ShaxterskKonvejernaya";
import { UgliKrasnopole } from "./components/UgliKrasnopole";
import { ShaxterskPortShaxtersk } from "./components/ShaxterskPortShaxtersk";
import { UgliShaxterskPort } from "./components/UgliShaxterskPort";
import { UgliShaxtersk } from "./components/UgliShaxtersk";
import { ShaxterskUgli } from "./components/ShaxterskUgli";
import { ShaxterskPortUgli } from "./components/ShaxterskPortUgli";



import { BusStopDotsCircle } from "./components/BusStopCircle";
import { KrasnopoleGSM } from "./components/KrasnopoleGSM";
import {Repost} from "./Repost";

export const RPPage = () => {
  const [otkuda, setOtkuda] = useState("selectOtkuda");
  const [kuda, setKuda] = useState("selectKuda");

  const [ugliudarContentVisible, setUgliudarContentVisible] = useState(false);
  const [udarUgliContentVisible, setUdarUgliContentVisible] = useState(false);
  // const [
  //   ugliRazrezUglitestContentVisible,
  //   setUgliRazrezUgliTestContentVisible,
  // ] = useState(false);
  const [UgliPortRazrezContentVisible, setUgliPortRazrezContentVisible] = useState(false);
  const [RazrezUgliPortContentVisible, setRazrezUgliPortContentVisible] = useState(false);

  const [stayContentVisible, setStayContentVisible] = useState(false);
  const [NotRouteContentVisible, setNotRouteContentVisible] = useState(false);

  // const [UglibusstopContentVision, setUglibusstopContentVision] =
  //   useState(false);

  const [ShaxterskRazrezContentVision, setShaxterskRazrezContentVision] = useState(false);
  const [RazrezShaxContentVision, setRazrezShaxContentVision] = useState(false);
  const [KrasnopoleRazrezContentVision, setKrasnopoleRazrezContentVision] = useState(false);
  const [RazrezKrasnopoleContentVision, setRazrezKrasnopoleContentVision] = useState(false);
  const [ShaxterskRMMRazrezContentVision, setShaxterskRMMRazrezContentVision] = useState(false);
  const [RazrezShaxterskRMMContentVision, setRazrezShaxterskRMMContentVision] = useState(false);
  const [ShaxterskUdarnoeContentVision, setShaxterskUdarnoeContentVision] = useState(false);
  const [UdarnoeShaxterskContentVision, setUdarnoeShaxterskContentVision] = useState(false);
  const [UglegorskRazrezContentVision, setUglegorskRazrezContentVision] = useState(false);
  const [RazrezUglegorskContentVision, setRazrezUglegorskContentVision] = useState(false);
  const [KonvejernayaUgliContentVision, setKonvejernayaUgliContentVision] = useState(false);
  const [ShaxterskShaxterskPortContentVision, setShaxterskShaxterskPortContentVision] = useState(false);
  const [ShaxterskUgliPortContentVision, setShaxterskUgliPortContentVision] = useState(false);
  const [UgliKonvejernayaContentVision, setUgliKonvejernayaContentVision] = useState(false);
  const [UgliPortShaxterskPortContentVision, setUgliPortShaxterskPortContentVision] = useState(false);
  const [UgliPortShaxterskContentVision, setUgliPortShaxterskContentVision] = useState(false);
  const [ShaxterskPortUgliPortContentVision, setShaxterskPortUgliPortContentVision] = useState(false);
  const [KonvejernayaShaxterskContentVision, setKonvejernayaShaxterskContentVision] = useState(false);
  const [KrasnopoleUgliContentVision, setKrasnopoleUgliContentVision] = useState(false);
  const [KrasnopoleShaxterskContentVision, setKrasnopoleShaxterskContentVision] = useState(false);
  const [ShaxterskKrasnopoleContentVision, setShaxterskKrasnopoleContentVision] = useState(false);
  const [ShaxterskKonvejernayaContentVision, setShaxterskKonvejernayaContentVision] = useState(false);
  const [UgliKrasnopoleContentVision, setUgliKrasnopoleContentVision] = useState(false);
  const [ShaxterskPortShaxterskContentVision, setShaxterskPortShaxterskContentVision] = useState(false);
  const [UgliShaxterskPortContentVision, setUgliShaxterskPortContentVision] = useState(false);
  const [UgliShaxterskContentVision, setUgliShaxterskContentVision] = useState(false);
  const [ShaxterskUgliContentVision, setShaxterskUgliContentVision] = useState(false);
  const [ShaxterskPortUgliContentVision, setShaxterskPortUgliContentVision] = useState(false);

  //krasnopoleGSM

  const [shaxterskkrasnopoleGSMContentVision, setshaxterskkrasnopoleGSMContentVision] = useState(false);
  const [krasnopoleGSMugliContentVision, setkrasnopoleGSMugliContentVision] = useState(false);
  const [uglikrasnopoleGSMContentVision, setuglikrasnopoleGSMContentVision] = useState(false);
  const [krasnopoleGSMshaxterskContentVision, setkrasnopoleGSMshaxterskContentVision] = useState(false);
  const [razrezKrasnopoleGSMContentVision, setrazrezKrasnopoleGSMContentVision] = useState(false);
  const [krasnopoleGSMrazrezContentVision, setkrasnopoleGSMrazrezContentVision] = useState(false);
  const [krasnopolekrasnopoleGSMContentVision, setkrasnopolekrasnopoleGSMContentVision] = useState(false);


  useEffect(() => {
    otkuda === "ugli" && kuda === "udarnoe"
      ? setUgliudarContentVisible(true)
      : setUgliudarContentVisible(false);
    otkuda === kuda
      ? setStayContentVisible(true)
      : setStayContentVisible(false);
    otkuda === "udarnoe" && kuda === "ugli"
      ? setUdarUgliContentVisible(true)
      : setUdarUgliContentVisible(false);

    // otkuda === "ugli" && kuda === "razrez"
    //   ? setUgliRazrezUgliTestContentVisible(true)
    //   : setUgliRazrezUgliTestContentVisible(false);

    otkuda === "ugliport" && kuda === "razrez"
      ? setUgliPortRazrezContentVisible(true)
      : setUgliPortRazrezContentVisible(false);
    otkuda === "razrez" && kuda === "ugliport"
      ? setRazrezUgliPortContentVisible(true)
      : setRazrezUgliPortContentVisible(false);

    otkuda === "haxtersk" && kuda === "razrez"
      ? setShaxterskRazrezContentVision(true)
      : setShaxterskRazrezContentVision(false);

    otkuda === "razrez" && kuda === "haxtersk"
      ? setRazrezShaxContentVision(true)
      : setRazrezShaxContentVision(false);

      otkuda === "razrez" && kuda === "haxtersk"
      ? setRazrezShaxContentVision(true)
      : setRazrezShaxContentVision(false);

      otkuda === "razrez" && kuda === "shaxterskRMM"
      ? setRazrezShaxterskRMMContentVision(true)
      : setRazrezShaxterskRMMContentVision(false);

      otkuda === "shaxterskRMM" && kuda === "razrez"
      ? setShaxterskRMMRazrezContentVision(true)
      : setShaxterskRMMRazrezContentVision(false);

      otkuda === "razrez" && kuda === "krasnopole"
      ? setRazrezKrasnopoleContentVision(true)
      : setRazrezKrasnopoleContentVision(false);

      otkuda === "krasnopole" && kuda === "razrez"
      ? setKrasnopoleRazrezContentVision(true)
      : setKrasnopoleRazrezContentVision(false);

      otkuda === "haxtersk" && kuda === "udarnoe"
      ? setShaxterskUdarnoeContentVision(true)
      : setShaxterskUdarnoeContentVision(false);

      otkuda === "udarnoe" && kuda === "haxtersk"
      ? setUdarnoeShaxterskContentVision(true)
      : setUdarnoeShaxterskContentVision(false);

      otkuda === "ugli" && kuda === "razrez"
      ? setUglegorskRazrezContentVision(true)
      : setUglegorskRazrezContentVision(false);

      otkuda === "razrez" && kuda === "ugli"
      ? setRazrezUglegorskContentVision(true)
      : setRazrezUglegorskContentVision(false);

      otkuda === "konvejernaya" && kuda === "ugli"
      ? setKonvejernayaUgliContentVision(true)
      : setKonvejernayaUgliContentVision(false);

      otkuda === "haxtersk" && kuda === "umph"
      ? setShaxterskShaxterskPortContentVision(true)
      : setShaxterskShaxterskPortContentVision(false);

      otkuda === "haxtersk" && kuda === "ugliport"
      ? setShaxterskUgliPortContentVision(true)
      : setShaxterskUgliPortContentVision(false);

      otkuda === "ugli" && kuda === "konvejernaya"
      ? setUgliKonvejernayaContentVision(true)
      : setUgliKonvejernayaContentVision(false);

      otkuda === "ugliport" && kuda === "umph"
      ? setUgliPortShaxterskPortContentVision(true)
      : setUgliPortShaxterskPortContentVision(false);

      otkuda === "ugliport" && kuda === "haxtersk"
      ? setUgliPortShaxterskContentVision(true)
      : setUgliPortShaxterskContentVision(false);

      otkuda === "umph" && kuda === "ugliport"
      ? setShaxterskPortUgliPortContentVision(true)
      : setShaxterskPortUgliPortContentVision(false);

      otkuda === "konvejernaya" && kuda === "haxtersk"
      ? setKonvejernayaShaxterskContentVision(true)
      : setKonvejernayaShaxterskContentVision(false);

      otkuda === "krasnopole" && kuda === "ugli"
      ? setKrasnopoleUgliContentVision(true)
      : setKrasnopoleUgliContentVision(false);


      otkuda === "krasnopole" && kuda === "haxtersk"
      ? setKrasnopoleShaxterskContentVision(true)
      : setKrasnopoleShaxterskContentVision(false);

      otkuda === "haxtersk" && kuda === "krasnopole"
      ? setShaxterskKrasnopoleContentVision(true)
      : setShaxterskKrasnopoleContentVision(false);

      otkuda === "haxtersk" && kuda === "konvejernaya"
      ? setShaxterskKonvejernayaContentVision(true)
      : setShaxterskKonvejernayaContentVision(false);

      otkuda === "ugli" && kuda === "krasnopole"
      ? setUgliKrasnopoleContentVision(true)
      : setUgliKrasnopoleContentVision(false);

      otkuda === "umph" && kuda === "haxtersk"
      ? setShaxterskPortShaxterskContentVision(true)
      : setShaxterskPortShaxterskContentVision(false);

      otkuda === "ugli" && kuda === "umph"
      ? setUgliShaxterskPortContentVision(true)
      : setUgliShaxterskPortContentVision(false);

      otkuda === "ugli" && kuda === "haxtersk"
      ? setUgliShaxterskContentVision(true)
      : setUgliShaxterskContentVision(false);

      otkuda === "haxtersk" && kuda === "ugli"
      ? setShaxterskUgliContentVision(true)
      : setShaxterskUgliContentVision(false);

      otkuda === "umph" && kuda === "ugli"
      ? setShaxterskPortUgliContentVision(true)
      : setShaxterskPortUgliContentVision(false);


      // krasnopole GSM
      otkuda === "haxtersk" && kuda === "krasnopoleGSM"
      ? setshaxterskkrasnopoleGSMContentVision(true)
      : setshaxterskkrasnopoleGSMContentVision(false);


      // ugli-krasnopoleGSM
      otkuda === "ugli" && kuda === "krasnopoleGSM"
      ? setuglikrasnopoleGSMContentVision(true)
      : setuglikrasnopoleGSMContentVision(false);

      otkuda === "krasnopoleGSM" && kuda === "ugli"
      ? setkrasnopoleGSMugliContentVision(true)
      : setkrasnopoleGSMugliContentVision(false);

      // krasnopoleGSM-shaxtersk
      otkuda === "krasnopoleGSM" && kuda === "haxtersk"
      ? setkrasnopoleGSMshaxterskContentVision(true)
      : setkrasnopoleGSMshaxterskContentVision(false);
      
      // Razrez-krasnopoleGSM
      otkuda === "razrez" && kuda === "krasnopoleGSM"
      ? setrazrezKrasnopoleGSMContentVision(true)
      : setrazrezKrasnopoleGSMContentVision(false);

      // krasnopoleGSM-razrez
      otkuda === "krasnopoleGSM" && kuda === "razrez"
      ? setkrasnopoleGSMrazrezContentVision(true)
      : setkrasnopoleGSMrazrezContentVision(false);

      // krasnopole-krasnopoleGSM
      otkuda === "krasnopole" && kuda === "krasnopoleGSM"
      ? setkrasnopolekrasnopoleGSMContentVision(true)
      : setkrasnopolekrasnopoleGSMContentVision(false);


// not route 

(otkuda === "ugli" && kuda === "shaxterskRMM") || 
(otkuda === "shaxterskRMM" && kuda === "ugli") || 
(otkuda === "razrez" && kuda === "konvejernaya") ||
(otkuda === "konvejernaya" && kuda === "razrez") ||
(otkuda === "haxtersk" && kuda === "shaxterskRMM") ||
(otkuda === "razrez" && kuda === "udarnoe") ||
(otkuda === "udarnoe" && kuda === "razrez") ||
(otkuda === "razrez" && kuda === "umph") ||
(otkuda === "umph" && kuda === "razrez") ||
(otkuda === "krasnopole" && kuda === "shaxterskRMM") ||
(otkuda === "shaxterskRMM" && kuda === "krasnopole") ||
(otkuda === "ugli" && kuda === "ugliport") ||
(otkuda === "ugliport" && kuda === "ugli") ||
(otkuda === "ugliport" && kuda === "udarnoe") ||
(otkuda === "udarnoe" && kuda === "ugliport") ||
(otkuda === "krasnopole" && kuda === "konvejernaya") ||
(otkuda === "konvejernaya" && kuda === "krasnopole") ||
(otkuda === "shaxterskRMM" && kuda === "konvejernaya") ||
(otkuda === "konvejernaya" && kuda === "shaxterskRMM") ||
(otkuda === "udarnoe" && kuda === "konvejernaya") ||
(otkuda === "konvejernaya" && kuda === "udarnoe") ||
(otkuda === "ugliport" && kuda === "konvejernaya") ||
(otkuda === "konvejernaya" && kuda === "ugliport") ||
(otkuda === "umph" && kuda === "konvejernaya") ||
(otkuda === "konvejernaya" && kuda === "umph") ||
(otkuda === "umph" && kuda === "shaxterskRMM") ||
(otkuda === "shaxterskRMM" && kuda === "umph") ||
(otkuda === "krasnopole" && kuda === "ugliport") ||
(otkuda === "ugliport" && kuda === "krasnopole") ||
(otkuda === "umph" && kuda === "udarnoe") ||
(otkuda === "udarnoe" && kuda === "umph") ||
(otkuda === "umph" && kuda === "krasnopole") ||
(otkuda === "krasnopole" && kuda === "umph") ||
(otkuda === "udarnoe" && kuda === "shaxterskRMM") ||
(otkuda === "shaxterskRMM" && kuda === "udarnoe") ||
(otkuda === "udarnoe" && kuda === "krasnopole") ||
(otkuda === "krasnopole" && kuda === "udarnoe") ||

(otkuda === "krasnopoleGSM" && kuda === "shaxterskRMM") ||
(otkuda === "krasnopoleGSM" && kuda === "umph") ||
(otkuda === "krasnopoleGSM" && kuda === "udarnoe") ||
(otkuda === "krasnopoleGSM" && kuda === "ugliport") ||
(otkuda === "krasnopoleGSM" && kuda === "konvejernaya") ||
(otkuda === "shaxterskRMM" && kuda === "krasnopoleGSM") ||
(otkuda === "umph" && kuda === "krasnopoleGSM") ||
(otkuda === "udarnoe" && kuda === "krasnopoleGSM") ||
(otkuda === "ugliport" && kuda === "krasnopoleGSM") ||
(otkuda === "konvejernaya" && kuda === "krasnopoleGSM") 

? setNotRouteContentVisible(true)
: setNotRouteContentVisible(false);

// end not route
  }, [otkuda, kuda]);

  const pointArray = {
    "ugli-udarnoe": [
      [49.07997666, 142.0620264],
      [49.07980399, 142.0676135],
      [49.08014713, 142.0734017],
      [49.1574702, 142.1119128],
      [49.12641012, 142.1406313],
    ],
    "udarnoe-ugli": [
      [49.12641012, 142.1406313],
      [49.1574702, 142.1119128],
      [49.08014713, 142.0734017],
      [49.07980399, 142.0676135],
      [49.07997666, 142.0620264],
    ],
    "ugli-razrez": [
      [49.07997666, 142.0620264],
      [49.07980399, 142.0676135],
      [49.08014713, 142.0734017],
      [48.961811, 142.181467],
    ],
    "haxtersk-razrez": [
      [49.158104, 142.101469],
      [49.080137, 142.073387],
      [49.07305, 142.09835],
      [48.961811, 142.181467],
    ],
    "razrez-haxtersk": [
      [48.961811, 142.181467],
      [49.158549, 142.107223],
      [49.158104, 142.101469],
      [49.159172, 142.092588],
    ],
    "haxtersk-udarnoe": [
      [49.158104, 142.101469],
      [49.158489, 142.106705],
      [49.157157, 142.112358],
      [49.127699, 142.140827],
    ],
    "udarnoe-haxtersk": [
      [49.127699, 142.140827],
      [49.157157, 142.112358],
      [49.158489, 142.106705],
      [49.158104, 142.101469],
    ],
    "haxtersk-ugli": [
      [49.158104, 142.101469],
      [49.158489, 142.106705],
      [49.080334, 142.073311],
      [49.07989, 142.06674],
      [49.080252, 142.062168],
    ],
    "ugli-haxtersk": [
      [49.079656, 142.082275],
      [49.080334, 142.073311],
      [49.07989, 142.06674],
      [49.080252, 142.062168],
      [49.158104, 142.101469],
      [49.159564, 142.093267],
    ],
    "haxtersk-ugliport": [
      [49.158411, 142.111203],
      [49.159681, 142.100528],
      [49.156428, 142.0948],
      [49.080252, 142.062168],
      [49.075604, 142.033067],
    ],
    "haxtersk-umph": [
      [49.158411, 142.111203],
      [49.158549, 142.107223],
      [49.160227, 142.098152],
      [49.15937, 142.079737],
      [49.16183, 142.057954],
    ],
    "umph-haxtersk": [
      [49.16183, 142.057954],
      [49.15937, 142.079737],
      [49.160227, 142.098152],
      [49.158549, 142.107223],
      [49.158411, 142.111203],
    ],
    "haxtersk-krasnopole": [
      [49.158549, 142.107223],
      [49.15932, 142.101489],
      [49.159172, 142.092588],
      [49.158104, 142.101469],
      [49.079941, 142.062059],
      [49.080137, 142.073388],
      [49.079024, 142.083204],
      [48.961811, 142.181467],
      [48.948748, 142.214088],
    ],
    "krasnopole-haxtersk": [
      [48.948748, 142.214088],
      [48.961811, 142.181467],
      [49.079024, 142.083204],
      [49.080137, 142.073388],
      [49.079941, 142.062059],
      [49.158104, 142.101469],
      [49.159172, 142.092588],
      [49.15932, 142.101489],
      [49.158549, 142.107223],
    ],
    "razrez-shaxterskRMM": [
      [48.961811, 142.181467],
      [49.158479, 142.102459],
      [49.173338, 142.113326],
      [49.176959, 142.117856],
      [49.174233, 142.140068],
    ],
    "shaxterskRMM-razrez": [
      [49.174233, 142.140068],
      [49.176959, 142.117856],
      [49.173338, 142.113326],
      [49.158479, 142.102459],
      [48.961811, 142.181467],
    ],
    "razrez-ugli": [
      [48.961811, 142.181467],
      // [48.937389, 142.21633],
      // [49.079656, 142.082275],
      [49.080137, 142.073388],
      [49.07989, 142.06674],
      [49.080252, 142.062168],
    ],
    // разрез 48.961811, 142.181467
    "razrez-ugliport": [
      [48.961811, 142.181467],
      [49.080137, 142.073388],
      [49.07989, 142.06674],
      [49.080252, 142.062168],
      [49.068389, 142.030411],
    ],
    "ugliport-razrez": [
      [49.068389, 142.030411],
      [49.080252, 142.062168],
      [49.07989, 142.06674],
      [49.080137, 142.073388],
      [48.961811, 142.181467],
    ],
    "razrez-krasnopole": [
      [48.961811, 142.181467],
      [48.948748, 142.214088],
    ],
    "krasnopole-razrez": [
      [48.948748, 142.214088],
      [48.961811, 142.181467],
    ],
    "krasnopole-ugli": [
      [48.948748, 142.214088],
      [49.079656, 142.082275],
      [49.080137, 142.073388],
      [49.07989, 142.06674],
      [49.080252, 142.062168],
    ],
    "ugli-krasnopole": [
      [49.080252, 142.062168],
      [49.07989, 142.06674],
      [49.080137, 142.073388],
      [49.079656, 142.082275],
      [48.948748, 142.214088],
    ],
    "ugliport-umph": [
      [49.068389, 142.030411],
      [49.080252, 142.062168],
      [49.080137, 142.073388],
      [49.158411, 142.111203],
      [49.158549, 142.107223],
      [49.160227, 142.098152],
      [49.159293, 142.07972],
      [49.161848, 142.058024],
    ],
    "umph-ugliport": [
      [49.161848, 142.058024],
      [49.159293, 142.07972],
      [49.160227, 142.098152],
      [49.158549, 142.107223],
      [49.158411, 142.111203],
      [49.080137, 142.073388],
      [49.080252, 142.062168],
      [49.068389, 142.030411],
    ],
    "ugli-umph": [
      [49.080252, 142.062168],
      [49.080137, 142.073388],
      [49.158411, 142.111203],
      [49.158549, 142.107223],
      [49.160227, 142.098152],
      [49.159293, 142.07972],
      [49.161848, 142.058024],
    ],
    "umph-ugli": [
      [49.161848, 142.058024],
      [49.159293, 142.07972],
      [49.160227, 142.098152],
      [49.158549, 142.107223],
      [49.158411, 142.111203],
      [49.080137, 142.073388],
      [49.080252, 142.062168],
    ],
    "ugli-konvejernaya": [
      [49.079766, 142.062746],
      [49.158649, 142.111267],
      [49.1647239, 142.0730492],
    ],
    "konvejernaya-ugli": [
      [49.1647239, 142.0730492],
      [49.158649, 142.111267],
      [49.079766, 142.062746],
    ],
    "haxtersk-konvejernaya": [
      [49.158649, 142.111267],
      [49.1647239, 142.0730492],
    ],
    "konvejernaya-haxtersk": [
      [49.1647239, 142.0730492],
      [49.158649, 142.111267],
    ],
    "haxtersk-krasnopoleGSM": [
      [49.158479, 142.102459],
      [49.158550, 142.107223],
      [49.080137, 142.073387],
      [48.961811, 142.181467],
      [48.942176, 142.228144],
    ],
    "ugli-krasnopoleGSM": [
      [49.080137, 142.073387],
      [48.961811, 142.181467],
      [48.942176, 142.228144],
    ],
    "krasnopoleGSM-ugli": [
      [48.942176, 142.228144],
      [48.961811, 142.181467],
      [49.080137, 142.073387],
    ],
    "krasnopoleGSM-haxtersk": [
      [48.942176, 142.228144],
      [48.961811, 142.181467],
      [49.080137, 142.073387],
      [49.158550, 142.107223],
      [49.158479, 142.102459],
      [49.159564, 142.093267],
    ],
    "razrez-krasnopoleGSM": [
      [48.961811, 142.181467],
      [48.942176, 142.228144],
    ],
    "krasnopoleGSM-razrez": [
      [48.942176, 142.228144],
      [48.961811, 142.181467],
    ],
    "krasnopole-krasnopoleGSM": [
      [48.948748, 142.214088],
      [48.942176, 142.228144],
    ],

  };

  const point = pointArray[`${otkuda}-${kuda}`] || [];
  
  // end point

  const handleOnChangeOtkuda = (e) => {
    setOtkuda(e.target.value);
  };
  const handleOnChangeKuda = (e) => {
    setKuda(e.target.value);
  };

  // maps route
  const mapState = {
    center: [49.15796892, 142.1031608],
    zoom: 12,
  };

  const swapValues = () => {
    const temp = otkuda;
    setOtkuda(kuda);
    setKuda(temp);
  };
// показ и скрытие остановок start

const [isVisible, setIsVisible] = useState(false);

const toggleVisibility = () => {
  setIsVisible(!isVisible);
};

// показ и скрытие остановок end
const [isVisibleMaps, setisVisibleMaps] = useState(false);
  const visibleMaps = () => {
    setisVisibleMaps(!isVisibleMaps);
  }

  const pokagikartu = !NotRouteContentVisible;
  // const [pokagikartu, setPokagikartu] = useState(false);

  // начальное положение

  const [noSelection, setNoSelection] = useState(true);


  useEffect(() => {
    if (otkuda === 'selectOtkuda' || kuda === 'selectKuda') {
      setNoSelection(true);
    } else {
      setNoSelection(false);
    }
  }, [otkuda, kuda]);

  return (
    <Container>
      <Row className="highlight ">
        <Col sm={12} className="text-center ">
          <h5>Выберите начальную и конечную точки маршрута</h5>
        </Col>
      </Row>
      <Row className="highlight justify-content-md-center">
        <Col xs={5} lg={5}>
          <Form.Select
            aria-label="otkuda"
            value={otkuda}
            onChange={handleOnChangeOtkuda}
          >
            <option value="selectOtkuda">От</option>
            <option value="haxtersk">Шахтерск</option>
            <option value="shaxterskRMM">Шахтерск (РММ)</option>
            <option value="umph">порт Шахтерск (УМПШ)</option>
            <hr />
            <option value="udarnoe">Ударное</option>
            <hr />
            <option value="razrez">Разрез</option>
            <hr />
            <option value="ugli">Углегорск</option>
            <option value="ugliport">Углегорск (порт)</option>
            <hr />
            <option value="krasnopole">Краснополье</option>
            <option value="krasnopoleGSM">Краснополье ГСМ</option>
            <option value="konvejernaya">Подстан. «Конвейерная»</option>

            
          </Form.Select>
        </Col>
        <Col xs={2} lg="auto" md="auto">
        <Button variant="secondary" onClick={swapValues}><FontAwesomeIcon icon={faRightLeft} /></Button>
        </Col>
        <Col xs={5} lg={5}>
          <Form.Select
            aria-label="kuda"
            value={kuda}
            onChange={handleOnChangeKuda}
          >
            <option value="selectKuda">До</option>
            <option value="haxtersk">Шахтерск</option>
            <option value="shaxterskRMM">Шахтерск (РММ)</option>
            <option value="umph">порт Шахтерск (УМПШ)</option>
            <hr />
            <option value="udarnoe">Ударное</option>
            <hr />
            <option value="razrez">Разрез</option>
            <hr />
            <option value="ugli">Углегорск</option>
            <option value="ugliport">Углегорск (порт)</option>
            <hr />
            <option value="krasnopole">Краснополье</option>
            <option value="krasnopoleGSM">Краснополье ГСМ</option>
            <option value="konvejernaya">Подстан. «Конвейерная»</option>

          </Form.Select>
        </Col>
        {/*<Col xs={4} lg="auto" md="auto">*/}
        {/*<Button  variant={isVisible ? 'danger' : 'secondary'}  onClick={toggleVisibility} title={isVisible ? "скрыть остановки" : "показать остановки"}><FontAwesomeIcon icon={isVisible ? faShop : faShopSlash} /></Button>*/}
        {/*</Col>*/}
      </Row>

      {/* Показать и скрыть остановки начало */}
      {(pokagikartu) && (!stayContentVisible) && (!noSelection) && (
      <Row className="highlight justify-content-md-center">
        <Col xs={6} lg={6} md={6}>
          <Button className={"w-100"} variant={isVisibleMaps ? 'warning' : 'secondary'} onClick={visibleMaps}> {isVisibleMaps ? 'Скрыть ' : 'Показать '} <FontAwesomeIcon icon={faMapLocation} /></Button>
          </Col>
        {isVisibleMaps && (
          <Col xs={6} lg={6} md={6}>
            <Button className={"w-100"}  variant={isVisible ? 'warning' : 'secondary'}  onClick={toggleVisibility} > {isVisible ? 'Скрыть ' : 'Показать '} <FontAwesomeIcon icon={faLocationDot} /></Button>
            </Col>
        )}
      </Row>
      )}
      {/* Показакать и скрыть остановки конец */}
      <br/>
      {NotRouteContentVisible && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <h4 className="text-center">По данному маршруту нет прямого автобусного сообщения</h4>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {stayContentVisible && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <h4 className="text-center">Стартовая и конечная точки совпадают</h4>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
{/*<Row>*/}
{/*  <Col>*/}
{/*    <Card>*/}
{/*      <Card.Body>*/}
{/*        {NotRouteContentVisible && (<h4 className="text-center">По данному маршруту нет прямого автобусного сообщения</h4>)}*/}
{/*        {stayContentVisible && (<h4 className="text-center">Стартовая и конечная точки совпадают</h4>)}*/}
{/*      </Card.Body>*/}
{/*    </Card>*/}
{/*  </Col>*/}
{/*</Row>*/}
      <Row>
        {isVisibleMaps && (
        <Col>
          <br /> <br />
          <Card>

            <Card.Body>
              {NotRouteContentVisible && (<h4 className="text-center">По данному маршруту нет прямого автобусного сообщения</h4>)}
              {stayContentVisible && (<h4 className="text-center">Стартовая и конечная точки совпадают</h4>)}

              {UgliShaxterskContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskShaxterskPortContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ugliudarContentVisible && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskPortShaxterskContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskKrasnopoleContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {KrasnopoleShaxterskContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskRMMRazrezContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {RazrezShaxterskRMMContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {RazrezUglegorskContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {UglegorskRazrezContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {RazrezUgliPortContentVisible && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {UgliPortRazrezContentVisible && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {KrasnopoleRazrezContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {RazrezKrasnopoleContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {KrasnopoleUgliContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {UgliKrasnopoleContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {UgliPortShaxterskPortContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskPortUgliPortContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {UdarnoeShaxterskContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {udarUgliContentVisible && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskRazrezContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {RazrezShaxContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskUdarnoeContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskUgliContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskUgliPortContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskPortUgliContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {UgliShaxterskPortContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {UgliKonvejernayaContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {KonvejernayaUgliContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {KonvejernayaShaxterskContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {ShaxterskKonvejernayaContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {/* krasnopole GSM */}
              {shaxterskkrasnopoleGSMContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {uglikrasnopoleGSMContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {krasnopoleGSMugliContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {krasnopoleGSMshaxterskContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {razrezKrasnopoleGSMContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {krasnopoleGSMrazrezContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
              {krasnopolekrasnopoleGSMContentVision && (<MapsAll point={point} state={mapState} isVisible={isVisible}/>)}
             


              {isVisible && <BusStopDotsCircle/>}
            </Card.Body>

          </Card>
        </Col>
        )}
      </Row>
      <Row >
        <Col>
        <br />
      <Card>
            <Card.Body>
              <Row>
                <Col><Clock /></Col>
              </Row>
            </Card.Body>
      </Card>
      <br /> 
      </Col>
      </Row>
      <Row className="highlight">
        {ugliudarContentVisible && <UgliUdar />}
        {udarUgliContentVisible && <UdarUgli />}
        {UgliPortRazrezContentVisible && <UgliPortRazrez />}
        {RazrezUgliPortContentVisible && <RazrezUgliPort />}
        {ShaxterskRazrezContentVision && <ShaxRazrez />}
        {RazrezShaxContentVision && <RazrezShax />}
        {KrasnopoleRazrezContentVision && <KrasnopoleRazrez />}
        {RazrezKrasnopoleContentVision && <RazrezKrasnopole />}
        {ShaxterskRMMRazrezContentVision && <ShaxterskRMMRazrez />}
        {RazrezShaxterskRMMContentVision && <RazrezShaxterskRMM />}
        {ShaxterskUdarnoeContentVision && <ShaxterskUdarnoe />}
        {UdarnoeShaxterskContentVision && <UdarnoeShaxtersk />}
        {UglegorskRazrezContentVision && <UglegorskRazrez />}
        {RazrezUglegorskContentVision && <RazrezUglegorsk />}
        {KonvejernayaUgliContentVision && <KonvejernayaUgli />}
        {ShaxterskShaxterskPortContentVision && <ShaxterskShaxterskPort />}
        {ShaxterskUgliPortContentVision && <ShaxterskUgliPort />}
        {UgliKonvejernayaContentVision && <UgliKonvejernaya />}
        {UgliPortShaxterskPortContentVision && <UgliPortShaxterskPort />}
        {UgliPortShaxterskContentVision && <UgliPortShaxtersk />}
        {ShaxterskPortUgliPortContentVision && <ShaxterskPortUgliPort />}
        {KonvejernayaShaxterskContentVision && <KonvejernayaShaxtersk />}
        {KrasnopoleUgliContentVision && <KrasnopoleUgli />}
        {KrasnopoleShaxterskContentVision && <KrasnopoleShaxtersk />}
        {ShaxterskKrasnopoleContentVision && <ShaxterskKrasnopole />}
        {ShaxterskKonvejernayaContentVision && <ShaxterskKonvejernaya />}
        {UgliKrasnopoleContentVision && <UgliKrasnopole />}
        {ShaxterskPortShaxterskContentVision && <ShaxterskPortShaxtersk />}
        {UgliShaxterskPortContentVision && <UgliShaxterskPort />}
        {UgliShaxterskContentVision && <UgliShaxtersk />}
        {ShaxterskUgliContentVision && <ShaxterskUgli />}
        {ShaxterskPortUgliContentVision && <ShaxterskPortUgli />}
         {/* раснополье ГСМ */}
         {shaxterskkrasnopoleGSMContentVision && <KrasnopoleGSM title={"shaxtersk-krasnopoleGSM"}/>}
         {uglikrasnopoleGSMContentVision && <KrasnopoleGSM title={"ugli-krasnopoleGSM"}/>}
         {krasnopoleGSMugliContentVision && <KrasnopoleGSM title={"krasnopoleGSM-ugli"}/>}
         {krasnopoleGSMshaxterskContentVision && <KrasnopoleGSM title={"krasnopoleGSM-shaxtersk"}/>}
         {razrezKrasnopoleGSMContentVision && <KrasnopoleGSM title={"Razrez-krasnopoleGSM"}/>}
         {krasnopoleGSMrazrezContentVision && <KrasnopoleGSM title={"krasnopoleGSM-razrez"}/>}
         {krasnopolekrasnopoleGSMContentVision && <KrasnopoleGSM title={"krasnopole-krasnopoleGSM"}/>}
      {/* bag repost */}

      </Row>
      <br/>
      <Row className="highlight">
        <Repost />
      </Row>
      <br/>
    </Container>
  );
};
